.title {
  margin-left: 15px;
}

.container_expenses_data {
  display: flex;
}

.expases_data_price {
  width: 12%;
}

.container_debtor {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container_payment_form {
  padding: 16px;
  background-color: #f7f7f7;
  display: flex;
  justify-content: center;
}

.container_payment_data {
  padding: 8px;
  background-color: #ededed;
  border-radius: 10px;
}

.bill_input {
  background: #f3f2f2;
  border: none;
  padding: 12px;
  font-size: 16px;
  margin-right: 15px;
  border-radius: 8px;
}

.bill_button {
  background-color: #ea5676;
  border: none;
  padding: 4px;
  color: white;
  font-weight: bold;
  padding: 12px;
  border-radius: 9px;
  cursor: pointer;
}

.bill_button:disabled {
  background-color: #cccccc;
  cursor: wait;
}

.bill {
  margin-top: 4px;
  font-size: 18px;
}

.form_search {
  padding: 16px;
  background-color: #f7f7f7;
  display: flex;
  justify-content: center;
}

.form_search-inputs {
  padding: 8px;
  background-color: #ededed;
  border-radius: 10px;
  width: 30%;
}

.form_search-input {
  background: #f3f2f2;
  border: none;
  padding: 12px;
  font-size: 16px;
  margin-right: 15px;
  width: 100%;
}

.form_search-checkbox_without_pay {
  margin-right: 16px;
}

.form_search-button {
  background-color: #ea5676;
  border: none;
  padding: 4px;
  color: white;
  font-weight: bold;
  padding: 12px;
  border-radius: 9px;
  cursor: pointer;
}

.form_search-button_delete {
  margin-left: 5px;
  background-color: #cccccc;
  border: none;
  padding: 4px;
  color: white;
  font-weight: bold;
  padding: 12px;
  border-radius: 9px;
  cursor: pointer;
}

@media (max-width: 450px) {
  .container_payment_data {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .bill_input {
    margin-bottom: 10px;
    height: 8px;
    margin-right: 0;
  }

  .form_search-inputs {
    width: 50%;
  }

  .form_search-container {
    display: flex;
  }

  .form_search-checkbox_without_pay {
    font-size: 14px;
  }

  .form_search-checkbox_seach_only_debtors {
    font-size: 14px;
  }

  .bill_checkbox_container {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .form_search-container_checkbox {
    display: flex;
    flex-direction: column;
  }

  .form_search-checkbox_without_pay {
    margin-bottom: 10px;
  }
}
