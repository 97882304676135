.container_debtor_info {
  text-transform: capitalize;
  margin-bottom: 12px;
  font-size: 20px;
}

.debtor_checkbox {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.debtor_expanse {
  margin-left: 4px;
  color: #555;
  font-size: 15px;
}

.debtor_trash {
  background: none;
  border: none;
  cursor: pointer;
  text-align: center;
  position: absolute;
  fill: #000000;
}

.debtor_checkbox:checked + .debtor_expanse {
  text-decoration: line-through;
}
