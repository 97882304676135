.modal_container {
  position: fixed;
  z-index: 2;
  background-color: rgba(0,0,0, 0.2);
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal_container.hidden {
  display: none;
}

.modal {
  position: relative;
  background: #ffffff;
  width: 40%;
  min-height: 270px;
  padding: 10px 40px;
  border-radius: 15px;
}

.modal-close {
  position: absolute;
  right: 24px;
  background: none;
  border: none;
  font-weight: bold;
  font-size: 24px;
  width: 32px;
  height: 32px;
  padding: 0;
  cursor: pointer;
}

.input.configuration_input {
  margin-bottom: 24px;
}

@media (max-width: 450px) {
  .modalTitle {
    font-size: 16px;
  }

  .input.configuration_input {
    margin-bottom:10px;
    height: 24px;
  }
}
