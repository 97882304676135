.header {
  height: 45px;
  padding: 30px 30px 0 0;
  background-color: rgba(187, 186, 186, 0.6);
  display: flex;
  justify-content: space-between;
}

.links {
  margin-left: 30px;
  text-decoration: none;
  color: black;
  display: inline;
  font-size: 17px;
  cursor: pointer;
}

.links:hover {
  text-decoration: underline;
  color: #747474;
}

.header_button {
  background: none;
  border: none;
}

.bills_information {
  display: flex;
}

.bills_information-current_salary {
  margin: 0 20px;
}

.bills_information-current_salary.-alert {
  color: red;
}

.bills_information-total_expense {
  margin: 0;
}

.bills_information-total_bills {
  margin: 0 20px;
}

@media (max-width: 770px) {
  .bills_information-current_salary {
    font-size: 12px;
  }

  .bills_information-total_expense {
    font-size: 12px;
  }

  .bills_information-total_bills {
    font-size: 12px;
  }

  .header_button {
    font-size: 12px;
  }
}

@media (max-width: 450px) {
  .header {
    display: block;
    padding: 36px 0 0;
  }

  .bills_information {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 4px;
  }

  .bills_information-current_salary {
    margin-top: -26px;
  }

  .bills_information-total_expense {
    margin-top: 4px;
  }

  .bills_information-total_bills {
    margin-top: 4px;
  }

  .header-links {
    margin: 0 20px;
    display: flex;
    justify-content: center;
    align-items: start;
  }

  .links {
    margin-left: 0;
  }
}
