.bill_date {
  position: sticky;
  top: 30px;
  background-color: #ffffff;
  width: 250px;
  display: flex;
  justify-content: center;
  z-index: 1;
}

.container_bill {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bill_title {
  margin: 0;
}

.container_bill-payments {
  display: flex;
}

.bill_payment {
  margin-right: 16px;
}

.bill_trash {
  background: none;
  border: none;
  cursor: pointer;
  text-align: center;
  fill: #000000;
}
